import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import moment from 'moment'
import { BacktestStore } from "../../redux/reducers/backtesting/backtestingReducer";
import BellIcon from '@mui/icons-material/NotificationsActive'
import NotificationsNone from '@mui/icons-material/NotificationsNone'
import Check from '@mui/icons-material/Check'
import Clear from '@mui/icons-material/Clear'

import { green, grey } from '@mui/material/colors';

interface BackTestResultRowProps
{
  row: BacktestStore
  isAdmin?: boolean
  onOpenItem: (item: BacktestStore) => void
}


export const CustomBackTestResultRow = ({ row, onOpenItem, isAdmin }: BackTestResultRowProps) =>
{
  return(
    <TableRow
            onClick={() => onOpenItem(row)}
            hover
             key={row._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
             { isAdmin && <TableCell component="th" scope="row">{row.username}</TableCell> }
              <TableCell component="th" scope="row">{row.friendlyName}</TableCell>
              <TableCell align="right">{row.config?.tradingPair}</TableCell>
              <TableCell align="right">every {row.schedule} hours</TableCell>
              <TableCell align="right">{row.lastRun ? moment(row.lastRun).fromNow() : "never"}</TableCell>
              <TableCell align="right">{moment(row.nextRun) < moment() ? "In Queue" : moment(row.nextRun).fromNow()}</TableCell>
              <TableCell align="right">{row.config?.isTakeProfitEnabled ? `${row.config?.takeProfitPercentage}%` : "Not enabled"}</TableCell>
              <TableCell align="right">{row.config?.isStopLossEnabled ? `${row.config?.stopLossPercentage}%` : "Not enabled"}</TableCell>
              <TableCell align="right">{row.config?.takeProfitThreshold ? `${row.config?.takeProfitThreshold}%` : "Not enabled"}</TableCell>
              <TableCell align="right">{row.notifyAfterComplete ? <BellIcon htmlColor={green[400]} /> : <NotificationsNone htmlColor={grey[500]} />}</TableCell>
              <TableCell align="right">{row.isEnabled ? <Check htmlColor={green[400]} /> : <Clear htmlColor={grey[500]} />}</TableCell>


            </TableRow>
  )
}
import IconButton from "@mui/material/IconButton"
import ListItem from "@mui/material/ListItem"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import EditIcon from "@mui/icons-material/Edit"
import Avatar from "@mui/material/Avatar"
import ListItemText from "@mui/material/ListItemText"
import { Symbol } from "../../../interface/Symbol"
import { CardActionArea } from "@mui/material"
import { blue } from "@mui/material/colors"

interface PairListItemProps
{
  item: Symbol
  onClick: (item: string) => void
  isSelected?: boolean
  onEdit?: () => void
}

export const PairListItem = ({ isSelected, item, onClick, onEdit }: PairListItemProps) => {
  return (
    <CardActionArea onClick={() => onClick(item.symbol)}> 
    <ListItem
    secondaryAction={
      isSelected ? <IconButton edge="end" onClick={onEdit}>
        <EditIcon />
      </IconButton> : <></>
    }
  >
    <ListItemAvatar>
      <Avatar style={styles.avatar}>
        {item.symbol[0] + item.symbol[1] + item.symbol[2]}
      </Avatar>
    </ListItemAvatar>
    <ListItemText
      primary={item.symbol}
      secondary={isSelected ? "Your selected pair" : `Status: ${item.status}`}
    />
  </ListItem>
  </CardActionArea>
  )
}

const styles = {
  avatar: {
    fontSize: 14,
    fontWeight: "700",
    color: "white",
    backgroundColor: blue[500]
  }
}
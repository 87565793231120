
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Controls } from '../backtesting/Controls';
import { BackTestResultRow } from '../backtesting/BackTestResultRow';
import { BacktestModal } from '../../components/modals/BacktestChartModal/BacktestModal';
import { BackTestRunEventModal } from '../../components/modals/BackTestRunEventModal/BackTestRunEventModal';
import { useFilterResults } from '../../utils/useFilterResults';
import { LottieLoading } from '../../components/placeholder/LottieLoading';
import { useEffect, useState } from 'react';
import { BackTest } from '../../interface/BackTest';
import { BackTestApi } from '../../api/BackTestApi';
import moment from 'moment';

interface SystemBacktestingTableProps
{
  ownTests: string
}

export const SystemBacktestingTable = ({ ownTests }: SystemBacktestingTableProps) => {

  const isNothingFound = () => !loading && filteredResults && filteredResults?.length === 0
  const isSomethingFound = () => !loading && filteredResults && filteredResults?.length > 0
  const [ results, setResults ] = useState<BackTest[] | undefined>([])
  const [ loading, setLoading ] = useState(false)
  const [ showBacktestModal, setShowBacktestModal ] = useState(false)
  const [ showRunEvents, setShowRunEvents ] = useState<BackTest | undefined>(undefined)
  const [ query, setQuery ] = useState("")

  const [ order, setOrder ] = useState<string>("newest")
  const [ fromTime, setFromTime ] = useState(8)

  const [ filteredResults ] = useFilterResults(query, results);

  const clearFilters = () =>
    {
      setOrder("newest")
      setFromTime(8)
    }

  const getBackTestResults = async () =>
    {
  
      setLoading(true)
      const btApi = new BackTestApi()
      const results = await btApi.getResults("all", "all", moment().subtract(fromTime, 'hours').toISOString(), order, ownTests);
      setResults(results)
      setLoading(false)
    }

    useEffect(() => {

      getBackTestResults()
  
    }, [ order, fromTime ])
    


  return (<>
  <Controls
      setQuery={setQuery}
      clearFilters={clearFilters}
      showChart={setShowBacktestModal}
      timeRange={fromTime}
      order={order}
      onOrderChange={setOrder}
      onTimeRangeChange={setFromTime}
     />

    { loading && <LottieLoading loop animation={require('../../animations/backtesting-lottie.json')} width={200} height={200} text='Getting Backtesting Results'/>} 

    { isNothingFound() && <LottieLoading loop={false} animation={require('../../animations/not-found-lottie.json')} width={200} height={200} text={`Looks like there's no results`}/>} 

    { isSomethingFound() && 
 
       <TableContainer component={Paper} style={styles.table}>
       <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader >
         <TableHead>
           <TableRow>
             <TableCell style={styles.header}>Run start time</TableCell>
             { ownTests === "yes" && <TableCell align="right" style={styles.header}>Test Config Name</TableCell> }
             <TableCell align="right" style={styles.header}>Trading Pair</TableCell>
             <TableCell align="right" style={styles.header}>Indicator</TableCell>
             <TableCell align="right" style={styles.header}>Win Percentage</TableCell>
             <TableCell align="right" style={styles.header}>Profit</TableCell>
             <TableCell align="right" style={styles.header}>Sales</TableCell>
             <TableCell align="right" style={styles.header}>Purchases</TableCell>
             <TableCell align="right" style={styles.header}>Candle</TableCell>
             <TableCell align="right" style={styles.header}>Settings</TableCell>

           </TableRow>
         </TableHead>
         <TableBody >
           {filteredResults && filteredResults.map((row, key) => <BackTestResultRow ownTests={ownTests} key={key} row={row} onShowRunEvents={setShowRunEvents} />)}
         </TableBody>
       </Table>
     </TableContainer>

    }

    <BacktestModal open={showBacktestModal} handleClose={() => setShowBacktestModal(false)} />
    <BackTestRunEventModal open={!!showRunEvents} handleClose={() => setShowRunEvents(undefined)} backtestItem={showRunEvents}/>

  </>)
}

const styles = {
  scrollView: {
    marginTop: 12,
    height: "50vh",
    overflow: "auto",
  },
  header: {
    fontWeight: "700"
  },
  table: {
    height: "80vh",
    marginTop: 16,
    marginBottom: 28,
  }
}
import { useState } from "react"

// MUI
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import CircularProgress from "@mui/material/CircularProgress"
import Alert, { AlertColor } from "@mui/material/Alert"
import Snackbar from "@mui/material/Snackbar"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"

// Icons
import SearchIcon from '@mui/icons-material/Search'
import SaveIcon from '@mui/icons-material/Save'
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'
import RunIcon from '@mui/icons-material/Start'


import TestIcon from '@mui/icons-material/GraphicEq'
import ClearIcon from '@mui/icons-material/Clear'

// Custom 
import { PairListItem } from "./PairListItem"
import { Indicators } from "../../Indicators"
import { LottieLoading } from "../../placeholder/LottieLoading"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { clearSettings, setBacktestConfig, setBacktestSettings } from "../../../redux/reducers/backtesting/backtestingReducer"
import { ApplicationState } from "../../../interface/ApplicationState"
import { TakeProfitAndStopLossConfig } from "./TakeProfitAndStopLossConfig"
import { ScheduleConfig } from "./ScheduleConfig"
import { ChartIntervalConfig } from "./ChartIntervalConfig"
import { useFilter } from "./hooks/useFilter"
import { useSymbols } from "./hooks/useSymbols"
import { useUpdateBacktest } from "./hooks/useUpdateBacktest"
import { Transition } from "../../../animations/Transition"
import Box from "@mui/material/Box"
import { ConfirmModal } from "../Confirm/ConfirmModal"
import { BackTestApi } from "../../../api/BackTestApi"
import { BuySellLimit } from "./BuySellLimit"

interface EditCustomBacktestProps
{
  open: boolean
  onClose: () => void
}

export const EditCustomBacktest = ({ open, onClose }: EditCustomBacktestProps) => {

  const { symbols, getSymbols, loadingSymbols } = useSymbols()
  const { handleUpdate, updating, updateResult, setUpdateResult } = useUpdateBacktest()
  const [ deleting, setDeleting ] = useState(false)
  const [ deleteResult, setDeleteResult ] = useState<{ message: string, color: AlertColor} | undefined>(undefined)
  const [ query, setQuery ] = useState("")
  const [ showConfirmDelete, setShowConfirmDelete ] = useState(false)


  const dispatch = useDispatch()
  const config = useSelector((state: ApplicationState) => state.backtesting.config)
  const settings = useSelector((state: ApplicationState) => state.backtesting)
  const filteredSymbols = useFilter(query, symbols)

  const handleClearSearch = () => setQuery("")
  
  const handleSelectPair = (pair: string) => {
    setQuery("")
    dispatch(setBacktestConfig({ tradingPair: pair }))
    dispatch(setBacktestSettings({ isEnabled: true }))
  } 

  const handleEditPair = async () => {
    await getSymbols()
    dispatch(setBacktestConfig({ tradingPair: ""}))
  }

  const handleClose = () => {
    dispatch(clearSettings())
    onClose()
  }

  const handleDelete = async () => {

    setDeleting(true)

    try
    {
      if(!settings._id) return
      setShowConfirmDelete(false)
      const response = await new BackTestApi().deleteCustomTest(settings._id)
      if(response)
      {
        setDeleteResult({ message: "Deleted test OK", color: "success" })
        setTimeout(() => handleClose(), 3000)
      }
     
    }
    catch(error)
    {
      const errorCast = error as any
      setDeleteResult({ message: errorCast?.message?.response?.data?.error || "Looks like something went wrong while deleting the custom backtest", color: "error" })
    }

    setDeleting(false)
  }

  const canSave = () => {
    if(!settings.accountBalanceUsdt || !settings.friendlyName || !settings.schedule || !settings.config?.tradingPair || !settings.config.chartInterval) return false
    return true
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" TransitionComponent={Transition}>
      <DialogTitle>
        <Grid container direction="row" spacing={2}> 
          <Grid item >
            <TestIcon style={styles.testTube} />
          </Grid>
          <Grid item flex={1}>
            <Typography variant="h6">Edit Custom Backtest</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}><CloseIcon style={styles.testTube} /></IconButton>
          </Grid>
        </Grid>
        </DialogTitle>

      { loadingSymbols && <LottieLoading animation={require("../../../animations/backtesting-lottie.json")} height={150} width={150} loop text="Getting Trading Pairs" containerHeight="20vh"/>}

     { !loadingSymbols && <DialogContent>

        <Grid container direction="column" spacing={2}>

          { !config?.tradingPair && <>
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight={700}>Search for a pair</Typography>
              <TextField 
                 value={query}
                 onChange={(e: any) => setQuery(e.target.value)}
                 InputProps={{
                  startAdornment: <SearchIcon sx={{ mr: 1}} />,
                  endAdornment: <IconButton onClick={handleClearSearch}><ClearIcon /></IconButton>
                 }}
                 autoFocus 
                 fullWidth 
                 placeholder="Search for a trading pair to begin" />
                { query && <Typography variant="caption">{`Found ${filteredSymbols.length} trading pairs`}</Typography> }
            </Grid>

            <Grid item>
              <List style={styles.list}>
                { filteredSymbols.map((item, key) => <PairListItem key={key} item={item} onClick={handleSelectPair} />)}
              </List>
            </Grid></> }


            { config?.tradingPair && <>

            <Grid item>
              <Typography sx={{ mb: 1 }} fontWeight="700">Test Description</Typography>
              <TextField 
                fullWidth 
                placeholder="Custom backtest name - E.G My first backtest" 
                value={settings.friendlyName} 
                onChange={(e: any) => dispatch(setBacktestSettings({ friendlyName: e.target.value }))}/>
            </Grid>
          
            <Grid item>
              <PairListItem onEdit={handleEditPair} isSelected onClick={() => {}} item={{ symbol: config?.tradingPair, baseAsset: "", quoteAsset: "", status: "Select Pair" } } />
            </Grid>

            <Grid item>
            <Typography sx={{ mb: 1 }} fontWeight="700">Test Enabled</Typography>
              <FormControlLabel checked={settings?.isEnabled} label="Enable or disable this scheduled test" onChange={(_, value) => dispatch(setBacktestSettings({ isEnabled: value }))} control={<Switch />} />
            </Grid>

            <Grid item>
            <Typography sx={{ mb: 1 }} fontWeight="700">Notify Config</Typography>
              <FormControlLabel checked={settings?.notifyAfterComplete} label="Send push message after test is complete" onChange={(_, value) => dispatch(setBacktestSettings({ notifyAfterComplete: value }))} control={<Switch />} />
            </Grid>

            <BuySellLimit />

            <ChartIntervalConfig />
            
            <ScheduleConfig />
          
            <TakeProfitAndStopLossConfig />

            <Grid item>
              <Indicators isBacktest />
            </Grid>
        
            </>}

        </Grid>

      </DialogContent> }

      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={() => setShowConfirmDelete(true)} startIcon={<DeleteIcon />}>Delete</Button>
        <Box flex={1} />
        <Button variant="outlined" onClick={() => handleUpdate({ ...settings, nextRun: new Date().toISOString() })} disabled={updating || !canSave()} startIcon={updating ? <CircularProgress size={16} /> : <RunIcon />}>{ updating ? "Scheduling..." : "Run now" }</Button>
        <Button variant="contained" onClick={() => handleUpdate(settings)} disabled={updating || !canSave()} startIcon={updating ? <CircularProgress size={16} /> : <SaveIcon />}>{ updating ? "Updating" : "Update" }</Button>
      </DialogActions>

      { updateResult && <Snackbar open autoHideDuration={3000} onClose={() => setUpdateResult(undefined)} >
        <Alert variant="filled" severity={updateResult.color}>{updateResult?.message}</Alert>
      </Snackbar> }

      { deleting && <Snackbar open onClose={() => {}} >
        <Alert variant="filled" severity="info">Deleting...</Alert>
      </Snackbar> }

      { deleteResult && <Snackbar open autoHideDuration={3000} onClose={() => setDeleteResult(undefined)} >
        <Alert variant="filled" severity={deleteResult.color}>{deleteResult?.message}</Alert>
      </Snackbar> }

      <ConfirmModal 
      id=""
      title="Confirm delete custom backtest"
      description="Are you sure you want to delete this backtest?"
      open={showConfirmDelete}
      onConfirm={handleDelete}
      handleClose={() => setShowConfirmDelete(false)}
      />

    </Dialog>
  )
}

const styles = {
  list: {
    overflow: "auto" as "auto",
    height: 300
  },
  testTube: {
    marginTop: 2,
  },
  card: {
    padding: 6,
  },
  switchItem: {
    paddingLeft: 16,
    paddingRight: 16,
    marginBottom: 6,
  }
}
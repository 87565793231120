import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import Typography from "@mui/material/Typography"
import { useDispatch, useSelector } from "react-redux"
import { ApplicationState } from "../../../interface/ApplicationState"
import { setBacktestConfig, setBacktestSettings } from "../../../redux/reducers/backtesting/backtestingReducer"
import { styles } from "./CreateCustomBackTest"
import TextField from "@mui/material/TextField"


export const BuySellLimit = () => {

  const dispatch = useDispatch()
  const config = useSelector((state: ApplicationState) => state.backtesting.config)
  const accountBalanceUsdt = useSelector((state: ApplicationState) => state.backtesting.accountBalanceUsdt)

  const updateMax = (e: any) => {
    dispatch(setBacktestConfig({ maxBuy: e.target.value }))
    dispatch(setBacktestConfig({ maxSell: e.target.value }))
  }
  
  return (
    <Grid item>
    <Typography sx={{ mb: 1 }} fontWeight="700">Configure you max invest and the account's initial balance</Typography>
    <Card style={styles.card}>

    <Grid item container direction="row" spacing={3}>
      <Grid item xs={6}>
        <TextField label="Max Invest" type="number" value={config?.maxBuy} onChange={updateMax} fullWidth placeholder=" Max Invest (Buy and Sell)" InputProps={{ startAdornment: "$", endAdornment: "USDT/TUSD" }} />
      </Grid>

      <Grid item xs={6}>
        <TextField label="Initial Account Balance" type="number" value={accountBalanceUsdt} onChange={(e: any) => dispatch(setBacktestSettings({ accountBalanceUsdt: e.target.value }))} fullWidth placeholder=" Account Balance" InputProps={{ startAdornment: "$", endAdornment: "USDT" }} />
      </Grid>

      </Grid>
    </Card>
  </Grid>
  )
}
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { CustomBackTestResultRow } from "./CustomBackTestResultRow"
import { BacktestStore } from "../../redux/reducers/backtesting/backtestingReducer"


interface BackTestingTableProps
{
  results: BacktestStore[]
  isAdmin?: boolean
  onOpenItem: (item: BacktestStore) => void
}

export const BackTestingTable = ({ results, onOpenItem, isAdmin } : BackTestingTableProps) => {
  return (
    <TableContainer component={Paper} style={styles.table}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader >
      <TableHead>
        <TableRow>
          { isAdmin && <TableCell style={styles.header}>User</TableCell> }
          <TableCell style={styles.header}>Test Name</TableCell>
          <TableCell align="right" style={styles.header}>Trading Pair</TableCell>
          <TableCell align="right" style={styles.header}>Schedule</TableCell>
          <TableCell align="right" style={styles.header}>Last Run</TableCell>
          <TableCell align="right" style={styles.header}>Next Run</TableCell>
          <TableCell align="right" style={styles.header}>Take Profit</TableCell>
          <TableCell align="right" style={styles.header}>Stop Loss</TableCell>
          <TableCell align="right" style={styles.header}>Min Sell Threshold</TableCell>
          <TableCell align="right" style={styles.header}>Alerts Enabled</TableCell>
          <TableCell align="right" style={styles.header}>Schedule Active</TableCell>

        </TableRow>
      </TableHead>
      <TableBody >
        {results && results.map((row, key) => <CustomBackTestResultRow key={key} row={row} onOpenItem={onOpenItem} isAdmin={isAdmin} />)}
      </TableBody>
    </Table>
  </TableContainer>
  )
}

const styles = {
  header: {
    fontWeight: "700"
  },
  table: {
    overflow: "auto",
    height: "75vh",
    marginTop: 16,
    marginBottom: 28,
  }
}
import Card from "@mui/material/Card"
import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { styles } from "./CreateCustomBackTest"
import Switch from "@mui/material/Switch"
import { useDispatch, useSelector } from "react-redux"
import { setBacktestConfig } from "../../../redux/reducers/backtesting/backtestingReducer"
import Slider from "@mui/material/Slider"
import { ApplicationState } from "../../../interface/ApplicationState"
import Divider from "@mui/material/Divider"


export const TakeProfitAndStopLossConfig = () => {

  const dispatch = useDispatch()
  const config = useSelector((state: ApplicationState) => state.backtesting.config)!!


  return (
    <Grid item>
    <Typography sx={{ mb: 1 }} fontWeight="700">Take Profit and Stop Loss</Typography>

    <Card style={styles.card}>
    <Grid item style={styles.switchItem} >
    <FormControlLabel
        control={<Switch checked={config?.isStopLossEnabled} onChange={(e) => dispatch(setBacktestConfig({ isStopLossEnabled: e.target.checked, stopLossPercentage: -10 }))} name="Enable Stop Loss" />}
        label={`Enable Stop Loss`} />

    { config.isStopLossEnabled && <><Slider 
      valueLabelDisplay="auto"
      value={config?.stopLossPercentage}
      step={0.1}
      min={-20}
      max={-0.1}
      onChange={(_, value) => dispatch(setBacktestConfig({ stopLossPercentage: value as number}))} />
    <Typography variant="caption">Stop loss will be triggered at {config?.stopLossPercentage}%</Typography></> }
    </Grid>
 
    <Grid item style={styles.switchItem}>
    <Divider />

    <FormControlLabel
        control={<Switch checked={config?.isTakeProfitEnabled} onChange={(e) => dispatch(setBacktestConfig({ isTakeProfitEnabled: e.target.checked, takeProfitPercentage: 5 }))} name="Enable Take Profit" />}
        label={`Enable Take Profit`} />

   { config.isTakeProfitEnabled && <><Slider 
      valueLabelDisplay="auto"
      value={config?.takeProfitPercentage}
      step={0.1}
      min={0.1}
      max={20}
      onChange={(_, value) => dispatch(setBacktestConfig({ takeProfitPercentage: value as number}))} />
    <Typography variant="caption">Take profit will be triggered at {config?.takeProfitPercentage}%</Typography> </>}
    </Grid>

    <Grid item style={styles.switchItem}>

    <Divider />

    <FormControlLabel
        control={<Switch checked={config?.isMinSellProfitEnabled} onChange={(e) => dispatch(setBacktestConfig({ isMinSellProfitEnabled: e.target.checked, takeProfitThreshold: 0.2 }))} name="Enable Min Sell Profit" />}
        label={`Enable Min Sell Threshold`} />

      { config.isMinSellProfitEnabled && <><Slider 
        valueLabelDisplay="auto"
        value={config?.takeProfitThreshold}
        step={0.1}
        min={0.1}
        max={2}
        onChange={(_, value) => dispatch(setBacktestConfig({ takeProfitThreshold: value as number}))}
      />
      <Typography variant="caption">Bot will only sell if profit meets percentage: {config.takeProfitThreshold}%</Typography></> }
    </Grid>

    </Card>

    </Grid>
  )
}
import { useState } from "react"
import { BackTestApi } from "../../../../api/BackTestApi"
import { AxiosError } from "axios"
import { BacktestStore } from "../../../../redux/reducers/backtesting/backtestingReducer"
import { AlertColor } from "@mui/material"


export const useUpdateBacktest = () =>
{
  const [ updating, setUpdating ] = useState(false)
  const [ updateResult, setUpdateResult ] = useState<{ message: string, color: AlertColor} | undefined>(undefined)

  
  const handleUpdate = async (settings: BacktestStore) => {

    setUpdating(true)

    try
    {
      const response = await new BackTestApi().updateCustomTest(settings)
      if(response) setUpdateResult({ message: "Updated test OK", color: "success"})
    }
    catch(error)
    {
      const errorCast = error as any
      setUpdateResult({ message: errorCast?.message?.response?.data?.error || "Looks like something went wrong while updating the custom backtest", color: "error" })
    }

    setUpdating(false)
  }

  return { handleUpdate, updateResult, updating, setUpdateResult }

  
}
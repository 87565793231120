import { useState } from "react"
import { UserApi } from "../../../../api/UserApi"
import { Symbol } from "../../../../interface/Symbol"

export const useSymbols = () =>
  {

    const [ loadingSymbols, setLoadingSymbols ] = useState(false)
    const [ symbols, setSymbols ] = useState<Symbol[]>([])
  
    const getSymbols = async () => {

      try
      {
        setLoadingSymbols(true)

        const response = await new UserApi().getSymbols()
    
        if(response)
        {
          setSymbols(response?.map(item => item)
          .filter(item => 
              item.symbol.endsWith("USDT") || 
              item.symbol.endsWith("TUSD") ||
              item.symbol.endsWith("BUSD") ))
        }
        setLoadingSymbols(false)
      }
      catch(error)
      {
        console.error(error)
        setLoadingSymbols(false)
      }
     
    }

    return { getSymbols, loadingSymbols, symbols }


}
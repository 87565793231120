import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../../interface/User";
import moment from 'moment'

export interface BacktestStore
{
  _id?: string
  isEnabled: boolean
  friendlyName: string
  username?: string;
  schedule: number
  userId?: string;
  accountBalanceUsdt: number;
  nextRun: string;
  lastRun: string;
  notifyAfterComplete: boolean
  config: Partial<User> | undefined
}

const initialState: BacktestStore = {
  isEnabled: true,
  userId: "",
  notifyAfterComplete: false,
  accountBalanceUsdt: 1000,
  friendlyName: "",
  schedule: 4,
  nextRun: "",
  lastRun: "",
  config: undefined
}

const backtestingReducer = createSlice({
  initialState,
  name: "Backtesting",
  reducers: {
    clearSettings: (state) => {
      state.config = undefined
      state.isEnabled = false
      state.friendlyName = ""
      state.schedule = 4
      state.nextRun = ""
      state.lastRun = ""
      state.username = ""
      state.accountBalanceUsdt = 1000
      state.userId = ""
      state._id = ""
    },
    setBacktestConfig: (state, action: PayloadAction<Partial<User>>) => {
      state.config = { ...state.config, ...action.payload }
    },
    setBacktestSettings: (state, action: PayloadAction<Partial<BacktestStore>>) => {
      Object.assign(state, action.payload);
    }
  }
})

export const { setBacktestConfig, setBacktestSettings, clearSettings } = backtestingReducer.actions 
export default backtestingReducer.reducer
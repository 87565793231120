

export const formatPrice = (price?: number) => {

  try
  {
    if(!price) return 0
    if(price > 1) return price.toFixed(2)
    return price
  }
  catch(error)
  {
    return price
  }



}
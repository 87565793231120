import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Chip from "@mui/material/Chip"
import { ApplicationState } from "../../../interface/ApplicationState"
import { useDispatch, useSelector } from "react-redux"
import { setBacktestSettings } from "../../../redux/reducers/backtesting/backtestingReducer"
import Card from "@mui/material/Card"
import moment from 'moment'
import { useEffect } from "react"
import { styles } from "./CreateCustomBackTest"

export const ScheduleConfig = () => {

  const dispatch = useDispatch()
  const settings = useSelector((state: ApplicationState) => state.backtesting)

  useEffect(() => {
    if(!settings.nextRun) dispatch(setBacktestSettings({ nextRun: moment().add(4, 'hours').toISOString() }))
  }, [])

  const handleSetNextRun = (schedule: number) => {
      dispatch(setBacktestSettings({ schedule, nextRun: moment().add(schedule, 'hours').toISOString() }))
  }

  return (
    <Grid item>
    <Typography sx={{ mb: 1 }} fontWeight="700">Select your schedule</Typography>
    <Card style={styles.card}>
    <Chip sx={{ mr: 1 }} label="Every 4 hours" onClick={() => handleSetNextRun(4)} variant={settings?.schedule === 4 ? "filled" : "outlined"} />
    <Chip sx={{ mr: 1 }} label="Every 8 hours" onClick={() => handleSetNextRun(8)}  variant={settings?.schedule === 8 ? "filled" : "outlined"} />
    <Chip sx={{ mr: 1 }} label="Every 12 hours" onClick={() => handleSetNextRun(12)}  variant={settings?.schedule === 12 ? "filled" : "outlined"} />
    <Chip sx={{ mr: 1 }} label="Every day" onClick={() => handleSetNextRun(24)}  variant={settings?.schedule === 24 ? "filled" : "outlined"} />
    <Chip sx={{ mr: 1 }} label="Every 48 hours" onClick={() => handleSetNextRun(48)}  variant={settings?.schedule === 48 ? "filled" : "outlined"} />
    <Chip sx={{ mr: 1 }} label="Every week" onClick={() => handleSetNextRun(168)}  variant={settings?.schedule === 168 ? "filled" : "outlined"} />
    <Typography sx={{ ml: 1, mt: 1 }}>Next run will be at {moment(settings.nextRun).format("hh:mm A ddd/MM/YYYY")} - ({moment(settings.nextRun).fromNow()})</Typography>
    </Card>
  </Grid>
  )
}
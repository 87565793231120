import { configureStore } from "@reduxjs/toolkit"
import createSagaMiddleware from '@redux-saga/core';
import { rootSaga } from './sagas'
import "regenerator-runtime/runtime";
import { authReducer } from "./reducers/auth/authReducer"
import { billingReducer } from "./reducers/billing/billingReducer"
import { chatReducer } from "./reducers/chat/chatReducer"
import { userReducer } from "./reducers/user/userReducer";
import backtestingReducer from "./reducers/backtesting/backtestingReducer";

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({ 
  reducer: {
    userStore: userReducer,
    auth: authReducer,
    billing: billingReducer,
    chat: chatReducer,
    backtesting: backtestingReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware),
})

sagaMiddleware.run(rootSaga)
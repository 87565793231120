import { BackTest, BackTestChartItem } from "../interface/BackTest";
import { BacktestStore } from "../redux/reducers/backtesting/backtestingReducer";
import { HttpService } from "./HttpService";

export class BackTestApi extends HttpService
{
  getResults = async (tradingPair?: string, indicator?: string, fromTime?: string, order?: string, ownTests?: string): Promise<BackTest[] | undefined> =>
  {
    const response = await this.request<BackTest[]>("GET", `backtesting?tradingPair=${tradingPair}&indicator=${indicator}&order=${order}&fromTime=${fromTime}&ownTests=${ownTests}`)
    if(response) return response.data
  }

  getChart = async (marketId: string): Promise<BackTestChartItem[] | undefined> =>
  {
    const response = await this.request<BackTestChartItem[]>("GET", `backtesting/chart?marketId=${marketId}`)
    if(response) return response.data
  }

  addCustomTest = async (test: BacktestStore) => {
    const response = await this.request<BacktestStore>("POST", `backtesting/custom`, test)
    if(response) return response.data
  }

  updateCustomTest = async (test: BacktestStore) => {
    const response = await this.request<BacktestStore>("PATCH", `backtesting/custom?backtestConfigId=${test._id}`, test)
    if(response) return response.data
  }

  deleteCustomTest = async (_id: string) => {
    const response = await this.request<BacktestStore>("DELETE", `backtesting/custom?backtestConfigId=${_id}`)
    if(response) return response.data
  }
  
  getCustomTests = async () => {
    const response = await this.request<BacktestStore[]>("GET", `backtesting/custom`)
    if(response) return response.data
  }

  getAllForUsers = async () => {
    const response = await this.request<BacktestStore[]>("GET", `backtesting/customForAllUsers`)
    if(response) return response.data
  }
}